/**
 * @file standalone/content_block_large.scss
 *
 * \brief Library file - shared styles for large content blocks
 *
 */

@import '../theme-bootstrap';

.content-block-large {
  height: auto;
  position: relative;
  width: 100%;
  &__inner,
  &__disclaimer {
    position: relative;
    &-over--image {
      position: absolute;
      z-index: 3;
      top: 0;
      bottom: auto;
      @include breakpoint($portrait-up) {
        top: 1em;
        position: relative;
      }
      // horizontal position
      &.content-block-large__inner-hpos--col {
        @include transform(translateX(-50%));
        #{$ldirection}: 50%;
        .content-block-large__logo {
          margin-#{$rdirection}: 20px;
        }
        .content-block-large__logo,
        .content-block-large__text-wrapper {
          display: inline-block;
          vertical-align: middle;
        }
      }
      &.center-0 {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
      // vertical position
      &.content-block-large__disclaimer-vpos--middle,
      &.content-block-large__inner-vpos--middle {
        height: 100%;
        top: 0;
        .content-block-large__content {
          @include vertical-horizontal-center;
        }
      }
      &.content-block-large__disclaimer-vpos--top,
      &.content-block-large__inner-vpos--top {
        top: 0;
        @include breakpoint($landscape-up) {
          top: 1em;
        }
      }
      &.content-block-large__inner-vpos--bottom {
        position: absolute;
        bottom: 0;
        top: auto;
        @include breakpoint($landscape-up) {
          bottom: 1em;
        }
      }
      .header--medium {
        @include breakpoint($mobile-only) {
          font-size: 23px;
        }
      }
    }
    &-vpos--top-height {
      @include breakpoint($portrait-only) {
        top: 5%;
      }
      @include breakpoint($landscape-up) {
        top: 25%;
      }
    }
    //
    //  Custom Border Options
    //
    &--top-border {
      .content-block-large__content {
        @include breakpoint($portrait-up) {
          padding-top: 25px;
          border-top: $border-thick-width solid $color-off-black;
        }
      }
    }
    &--logo-border {
      .content-block-large__logo {
        margin-bottom: 20px;
        border-bottom: 1px solid $color-off-black;
      }
    }
    &--row-1-border {
      .content-block-large__text--1 {
        margin: 20px auto;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-off-black;
      }
    }
    &--bottom-border {
      .content-block-large__content {
        @include breakpoint($portrait-up) {
          padding-bottom: 25px;
          border-bottom: $border-thick-width solid $color-off-black;
        }
      }
    }
    &--lowercase {
      .content-block-large__text {
        text-transform: none;
      }
    }
    .content-block-large__text-wrapper {
      .care_section {
        @include breakpoint($portrait-up) {
          margin-top: 8px;
        }
        @include breakpoint($landscape-up) {
          margin-top: 40px;
        }
      }
      .care_section_content {
        @include breakpoint($portrait-up) {
          margin-top: 12px;
        }
        @include breakpoint($landscape-up) {
          margin-top: 15px;
        }
      }
      .offer_sub_header {
        @include breakpoint($portrait-up) {
          font-size: 18px;
        }
        @include breakpoint($landscape-up) {
          font-size: 20px;
        }
      }
      .offer_text {
        @include breakpoint($portrait-up) {
          font-size: 14px;
        }
        @include breakpoint($landscape-up) {
          font-size: 18px;
        }
      }
      .offer_banner_header {
        @include breakpoint($portrait-up) {
          margin-top: 2%;
        }
        @include breakpoint($landscape-up) {
          margin-top: 3%;
        }
        @include breakpoint($xlarge-up) {
          margin-top: 5%;
        }
      }
      .revive_block {
        @include breakpoint($portrait-up) {
          font-size: 19px;
        }
        @include breakpoint($landscape-up) {
          font-size: 34px;
        }
      }
      .cooling_content {
        @include breakpoint($portrait-up) {
          font-size: 22px;
        }
        @include breakpoint($landscape-up) {
          font-size: 28px;
        }
      }
    }
    &-vpos--bottom {
      bottom: 1em;
      &-height {
        bottom: 18%;
      }
    }
    // content widths
    &.large-width-60 {
      .content-block-large__cta-wrap {
        @include breakpoint($landscape-up) {
          margin-top: 20px;
        }
      }
    }
    &.large-width-80 {
      .content-block-large__cta-wrap {
        @include breakpoint($portrait-up) {
          margin-top: 25px;
        }
        @include breakpoint($landscape-up) {
          margin-top: 30px;
        }
      }
    }
    @include breakpoint($portrait-up) {
      position: absolute;
      z-index: 3;
      top: 1em;
      bottom: auto;
      // horizontal position
      &-hpos--col {
        @include transform(translateX(-50%));
        #{$ldirection}: 50%;
        .content-block-large__logo {
          margin-#{$rdirection}: 20px;
        }
        .content-block-large__logo,
        .content-block-large__text-wrapper {
          display: inline-block;
          vertical-align: middle;
        }
      }
      &.large-center-0 {
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
      // vertical position
      &-vpos--middle {
        height: 100%;
        top: 0;
        .content-block-large__content {
          @include vertical-horizontal-center;
        }
      }
      &-vpos--top {
        top: 1em;
      }
      &-vpos--bottom {
        position: absolute;
        bottom: 1em;
        top: auto;
      }
    }
  }
  &__content {
    padding: 18px 15px 25px;
    .content-block-large.vertical-rules & {
      padding: 0;
    }
    @include breakpoint($portrait-up) {
      padding: 0;
      // when both top and bottom borders are set, pad the inner content more
      .content-block-large__inner--bottom-border.content-block-large__inner--top-border & {
        padding-#{$ldirection}: 10%;
        padding-#{$rdirection}: 10%;
      }
    }
    &.line-mink {
      padding: 35px 0;
      border-bottom: 6px solid $color-off-black;
      border-top: 6px solid $color-off-black;
    }
    &.line-white {
      border-bottom: 6px solid $white;
      border-top: 6px solid $white;
      padding: 35px 0;
    }
  }
  &__text {
    p {
      line-height: inherit;
      margin: 0;
    }
    &--2,
    &--5 {
      margin-top: 7px;
    }
    &--3,
    &--4 {
      margin-top: 2px;
    }
  }
  &__image {
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  &__cta-wrap {
    margin-top: 15px;
    @include breakpoint($portrait-up) {
      margin: 20px 0 10px;
    }
  }
  &__appstore-image {
    display: inline-block;
    width: 120px;
  }
  &__cta {
    display: inline-block;
    // CTA next to CTA
    & + .content-block-large__cta {
      margin-#{$ldirection}: 15px;
      @include breakpoint($portrait-up) {
        margin-#{$ldirection}: 30px;
      }
    }
  }
  // If we have no image but are using vpos middle, tweak the design
  &--no-image {
    padding: 0;
    @include breakpoint($portrait-up) {
      padding: 50px 0;
    }
    .content-block-large__inner-vpos--middle {
      @include breakpoint($portrait-up) {
        position: relative;
      }
      .content-block-large__content {
        #{$ldirection}: auto;
        @include transform(none);
      }
    }
  }
  &__disclaimer {
    .content-block-large__content {
      padding: 0 15px;
      @include breakpoint($portrait-up) {
        padding: 0;
      }
    }
    &-over--image {
      @include breakpoint($mobile-only) {
        top: auto;
      }
    }
    &-vpos--mobile {
      &--bottom {
        bottom: 1em;
        &-height {
          bottom: 13%;
        }
      }
    }
    &-vpos--bottom {
      @include breakpoint($portrait-up) {
        bottom: 1em;
      }
      &-height {
        @include breakpoint($portrait-only) {
          top: auto;
          bottom: 12%;
        }
        @include breakpoint($landscape-up) {
          bottom: 18%;
        }
      }
    }
  }
}
